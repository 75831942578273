:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root.dark {
  --dt-html-background: #212529;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable td.dt-control:before {
  box-sizing: border-box;
  content: "";
  border: 5px solid #0000;
  border-left: 10px solid #00000080;
  border-right-width: 0;
  display: inline-block;
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  border: 5px solid #0000;
  border-top: 10px solid #00000080;
  border-bottom-width: 0;
}

html.dark table.dataTable td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable td.dt-control:before {
  border-left-color: #ffffff80;
}

html.dark table.dataTable tr.dt-hasChild td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable tr.dt-hasChild td.dt-control:before {
  border-top-color: #ffffff80;
  border-left-color: #0000;
}

div.dt-scroll-body thead tr, div.dt-scroll-body tfoot tr {
  height: 0;
}

div.dt-scroll-body thead tr th, div.dt-scroll-body thead tr td, div.dt-scroll-body tfoot tr th, div.dt-scroll-body tfoot tr td {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.dt-scroll-body thead tr th div.dt-scroll-sizing, div.dt-scroll-body thead tr td div.dt-scroll-sizing, div.dt-scroll-body tfoot tr th div.dt-scroll-sizing, div.dt-scroll-body tfoot tr td div.dt-scroll-sizing {
  height: 0 !important;
  overflow: hidden !important;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before {
  content: "▲";
  content: "▲" / "";
  display: block;
  position: absolute;
  bottom: 50%;
}

table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  content: "▼";
  content: "▼" / "";
  display: block;
  position: absolute;
  top: 50%;
}

table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > th.dt-ordering-asc, table.dataTable thead > tr > th.dt-ordering-desc, table.dataTable thead > tr > td.dt-orderable-asc, table.dataTable thead > tr > td.dt-orderable-desc, table.dataTable thead > tr > td.dt-ordering-asc, table.dataTable thead > tr > td.dt-ordering-desc {
  padding-right: 30px;
  position: relative;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  width: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
}

table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: .125;
  font-size: .8em;
  line-height: 9px;
  left: 0;
}

table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > td.dt-orderable-asc, table.dataTable thead > tr > td.dt-orderable-desc {
  cursor: pointer;
}

table.dataTable thead > tr > th.dt-orderable-asc:hover, table.dataTable thead > tr > th.dt-orderable-desc:hover, table.dataTable thead > tr > td.dt-orderable-asc:hover, table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline-offset: -2px;
  outline: 2px solid #0000000d;
}

table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after, table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: .6;
}

table.dataTable thead > tr > th.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > th.sorting_asc_disabled span.dt-column-order:before, table.dataTable thead > tr > td.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > td.sorting_asc_disabled span.dt-column-order:before {
  display: none;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

div.dt-scroll-body > table.dataTable > thead > tr > th, div.dt-scroll-body > table.dataTable > thead > tr > td {
  overflow: hidden;
}

:root.dark table.dataTable thead > tr > th.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > th.dt-orderable-desc:hover, :root.dark table.dataTable thead > tr > td.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > td.dt-orderable-desc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > th.dt-orderable-asc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > th.dt-orderable-desc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > td.dt-orderable-asc:hover, :root[data-bs-theme="dark"] table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline: 2px solid #ffffff0d;
}

div.dt-processing {
  text-align: center;
  z-index: 10;
  width: 200px;
  margin-top: -22px;
  margin-left: -100px;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dt-processing > div:last-child {
  width: 80px;
  height: 15px;
  margin: 1em auto;
  position: relative;
}

div.dt-processing > div:last-child > div {
  background: #0d6efd;
  background: rgb(var(--dt-row-selected));
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 0;
}

div.dt-processing > div:last-child > div:first-child {
  animation: .6s infinite datatables-loader-1;
  left: 8px;
}

div.dt-processing > div:last-child > div:nth-child(2) {
  animation: .6s infinite datatables-loader-2;
  left: 8px;
}

div.dt-processing > div:last-child > div:nth-child(3) {
  animation: .6s infinite datatables-loader-2;
  left: 32px;
}

div.dt-processing > div:last-child > div:nth-child(4) {
  animation: .6s infinite datatables-loader-3;
  left: 56px;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th, table.dataTable td {
  box-sizing: border-box;
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable th.dt-empty, table.dataTable td.dt-empty {
  text-align: center;
  vertical-align: top;
}

table.dataTable th.dt-type-numeric, table.dataTable th.dt-type-date, table.dataTable td.dt-type-numeric, table.dataTable td.dt-type-date {
  text-align: right;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td, table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable {
  border-spacing: 0;
  width: 100%;
  margin: 0 auto;
}

table.dataTable thead th, table.dataTable tfoot th {
  font-weight: bold;
}

table.dataTable > thead > tr > th, table.dataTable > thead > tr > td {
  border-bottom: 1px solid #0000004d;
  padding: 10px;
}

table.dataTable > thead > tr > th:active, table.dataTable > thead > tr > td:active {
  outline: none;
}

table.dataTable > tfoot > tr > th, table.dataTable > tfoot > tr > td {
  border-top: 1px solid #0000004d;
  padding: 10px 10px 6px;
}

table.dataTable > tbody > tr {
  background-color: #0000;
}

table.dataTable > tbody > tr:first-child > * {
  border-top: none;
}

table.dataTable > tbody > tr:last-child > * {
  border-bottom: none;
}

table.dataTable > tbody > tr.selected > * {
  box-shadow: inset 0 0 0 9999px #0d6efde6;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .9);
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable > tbody > tr.selected a {
  color: rgb(var(--dt-row-selected-link));
}

table.dataTable > tbody > tr > th, table.dataTable > tbody > tr > td {
  padding: 8px 10px;
}

table.dataTable.row-border > tbody > tr > *, table.dataTable.display > tbody > tr > * {
  border-top: 1px solid #00000026;
}

table.dataTable.row-border > tbody > tr:first-child > *, table.dataTable.display > tbody > tr:first-child > * {
  border-top: none;
}

table.dataTable.row-border > tbody > tr.selected + tr.selected > td, table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0d6efda6;
  border-top-color: rgba(var(--dt-row-selected), .65);
}

table.dataTable.cell-border > tbody > tr > * {
  border-top: 1px solid #00000026;
  border-right: 1px solid #00000026;
}

table.dataTable.cell-border > tbody > tr > :first-child {
  border-left: 1px solid #00000026;
}

table.dataTable.cell-border > tbody > tr:first-child > * {
  border-top: 1px solid #0000004d;
}

table.dataTable.stripe > tbody > tr:nth-child(odd) > *, table.dataTable.display > tbody > tr:nth-child(odd) > * {
  box-shadow: inset 0 0 0 9999px #00000006;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .023);
}

table.dataTable.stripe > tbody > tr:nth-child(odd).selected > *, table.dataTable.display > tbody > tr:nth-child(odd).selected > * {
  box-shadow: inset 0 0 0 9999px #0d6efdeb;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .923);
}

table.dataTable.hover > tbody > tr:hover > *, table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px #00000009;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .035);
}

table.dataTable.hover > tbody > tr.selected:hover > *, table.dataTable.display > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0d6efd !important;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
}

table.dataTable.order-column > tbody tr > .sorting_1, table.dataTable.order-column > tbody tr > .sorting_2, table.dataTable.order-column > tbody tr > .sorting_3, table.dataTable.display > tbody tr > .sorting_1, table.dataTable.display > tbody tr > .sorting_2, table.dataTable.display > tbody tr > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000005;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .019);
}

table.dataTable.order-column > tbody tr.selected > .sorting_1, table.dataTable.order-column > tbody tr.selected > .sorting_2, table.dataTable.order-column > tbody tr.selected > .sorting_3, table.dataTable.display > tbody tr.selected > .sorting_1, table.dataTable.display > tbody tr.selected > .sorting_2, table.dataTable.display > tbody tr.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdea;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .919);
}

table.dataTable.display > tbody > tr:nth-child(odd) > .sorting_1, table.dataTable.order-column.stripe > tbody > tr:nth-child(odd) > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0000000e;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .054);
}

table.dataTable.display > tbody > tr:nth-child(odd) > .sorting_2, table.dataTable.order-column.stripe > tbody > tr:nth-child(odd) > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0000000c;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .047);
}

table.dataTable.display > tbody > tr:nth-child(odd) > .sorting_3, table.dataTable.order-column.stripe > tbody > tr:nth-child(odd) > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0000000a;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .039);
}

table.dataTable.display > tbody > tr:nth-child(odd).selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr:nth-child(odd).selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdf3;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .954);
}

table.dataTable.display > tbody > tr:nth-child(odd).selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr:nth-child(odd).selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efdf1;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .947);
}

table.dataTable.display > tbody > tr:nth-child(odd).selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr:nth-child(odd).selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdef;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .939);
}

table.dataTable.display > tbody > tr.even > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #00000005;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .019);
}

table.dataTable.display > tbody > tr.even > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #00000003;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .011);
}

table.dataTable.display > tbody > tr.even > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000001;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .003);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdea;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .919);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efde8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .911);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efde6;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .903);
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #00000015;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .082);
}

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #00000013;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .074);
}

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000010;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .062);
}

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdfa;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .982);
}

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efdf8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .974);
}

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdf5;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .962);
}

table.dataTable.compact thead th, table.dataTable.compact thead td, table.dataTable.compact tfoot th, table.dataTable.compact tfoot td, table.dataTable.compact tbody th, table.dataTable.compact tbody td {
  padding: 4px;
}

div.dt-container {
  clear: both;
  position: relative;
}

div.dt-container div.dt-layout-row {
  clear: both;
  width: 100%;
  display: table;
}

div.dt-container div.dt-layout-row.dt-layout-table, div.dt-container div.dt-layout-row.dt-layout-table div.dt-layout-cell {
  display: block;
}

div.dt-container div.dt-layout-cell {
  vertical-align: middle;
  padding: 5px 0;
  display: table-cell;
}

div.dt-container div.dt-layout-cell.dt-full {
  text-align: center;
}

div.dt-container div.dt-layout-cell.dt-start {
  text-align: left;
}

div.dt-container div.dt-layout-cell.dt-end {
  text-align: right;
}

div.dt-container div.dt-layout-cell:empty {
  display: none;
}

div.dt-container .dt-search input {
  color: inherit;
  background-color: #0000;
  border: 1px solid #aaa;
  border-radius: 3px;
  margin-left: 3px;
  padding: 5px;
}

div.dt-container .dt-input {
  color: inherit;
  background-color: #0000;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
}

div.dt-container select.dt-input {
  padding: 4px;
}

div.dt-container .dt-paging .dt-paging-button {
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid #0000;
  border-radius: 2px;
  min-width: 1.5em;
  margin-left: 2px;
  padding: .5em 1em;
  display: inline-block;
  color: inherit !important;
  text-decoration: none !important;
}

div.dt-container .dt-paging .dt-paging-button.current, div.dt-container .dt-paging .dt-paging-button.current:hover {
  background: -moz-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: -ms-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: linear-gradient(#e6e6e60d 0%, #0000000d 100%);
  border: 1px solid #0000004d;
  color: inherit !important;
}

div.dt-container .dt-paging .dt-paging-button.disabled, div.dt-container .dt-paging .dt-paging-button.disabled:hover, div.dt-container .dt-paging .dt-paging-button.disabled:active {
  cursor: default;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  color: #00000080 !important;
}

div.dt-container .dt-paging .dt-paging-button:hover {
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(#585858 0%, #111 100%);
  border: 1px solid #111;
  color: #fff !important;
}

div.dt-container .dt-paging .dt-paging-button:active {
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(#2b2b2b 0%, #0c0c0c 100%);
  outline: none;
  box-shadow: inset 0 0 3px #111;
}

div.dt-container .dt-paging .ellipsis {
  padding: 0 1em;
}

div.dt-container .dt-length, div.dt-container .dt-search, div.dt-container .dt-info, div.dt-container .dt-processing, div.dt-container .dt-paging {
  color: inherit;
}

div.dt-container .dataTables_scroll {
  clear: both;
}

div.dt-container .dataTables_scroll div.dt-scroll-body {
  -webkit-overflow-scrolling: touch;
}

div.dt-container .dataTables_scroll div.dt-scroll-body > table > thead > tr > th, div.dt-container .dataTables_scroll div.dt-scroll-body > table > thead > tr > td, div.dt-container .dataTables_scroll div.dt-scroll-body > table > tbody > tr > th, div.dt-container .dataTables_scroll div.dt-scroll-body > table > tbody > tr > td {
  vertical-align: middle;
}

div.dt-container .dataTables_scroll div.dt-scroll-body > table > thead > tr > th > div.dataTables_sizing, div.dt-container .dataTables_scroll div.dt-scroll-body > table > thead > tr > td > div.dataTables_sizing, div.dt-container .dataTables_scroll div.dt-scroll-body > table > tbody > tr > th > div.dataTables_sizing, div.dt-container .dataTables_scroll div.dt-scroll-body > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

div.dt-container.dt-empty-footer tbody > tr:last-child > *, div.dt-container.dt-empty-footer .dt-scroll-body {
  border-bottom: 1px solid #0000004d;
}

div.dt-container.dt-empty-footer .dt-scroll-body tbody > tr:last-child > * {
  border-bottom: none;
}

@media screen and (width <= 767px) {
  div.dt-container div.dt-layout-row, div.dt-container div.dt-layout-cell {
    display: block;
  }

  div.dt-container div.dt-layout-cell.dt-full, div.dt-container div.dt-layout-cell.dt-start, div.dt-container div.dt-layout-cell.dt-end {
    text-align: center;
  }
}

@media screen and (width <= 640px) {
  .dt-container .dt-length, .dt-container .dt-search {
    float: none;
    text-align: center;
  }

  .dt-container .dt-search {
    margin-top: .5em;
  }
}

html.dark {
  --dt-row-hover: 255, 255, 255;
  --dt-row-stripe: 255, 255, 255;
  --dt-column-ordering: 255, 255, 255;
}

html.dark table.dataTable > thead > tr > th, html.dark table.dataTable > thead > tr > td {
  border-bottom: 1px solid #595b5e;
}

html.dark table.dataTable > thead > tr > th:active, html.dark table.dataTable > thead > tr > td:active {
  outline: none;
}

html.dark table.dataTable > tfoot > tr > th, html.dark table.dataTable > tfoot > tr > td {
  border-top: 1px solid #595b5e;
}

html.dark table.dataTable.row-border > tbody > tr > *, html.dark table.dataTable.display > tbody > tr > * {
  border-top: 1px solid #404346;
}

html.dark table.dataTable.row-border > tbody > tr:first-child > *, html.dark table.dataTable.display > tbody > tr:first-child > * {
  border-top: none;
}

html.dark table.dataTable.row-border > tbody > tr.selected + tr.selected > td, html.dark table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0d6efda6;
  border-top-color: rgba(var(--dt-row-selected), .65);
}

html.dark table.dataTable.cell-border > tbody > tr > th, html.dark table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid #404346;
  border-right: 1px solid #404346;
}

html.dark table.dataTable.cell-border > tbody > tr > th:first-child, html.dark table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid #404346;
}

html.dark .dt-container.dt-empty-footer table.dataTable {
  border-bottom: 1px solid #595b5e;
}

html.dark .dt-container .dt-search input, html.dark .dt-container .dt-length select {
  background-color: var(--dt-html-background);
  border: 1px solid #fff3;
}

html.dark .dt-container .dt-paging .dt-paging-button.current, html.dark .dt-container .dt-paging .dt-paging-button.current:hover {
  background: #ffffff26;
  border: 1px solid #595b5e;
}

html.dark .dt-container .dt-paging .dt-paging-button.disabled, html.dark .dt-container .dt-paging .dt-paging-button.disabled:hover, html.dark .dt-container .dt-paging .dt-paging-button.disabled:active {
  color: #666 !important;
}

html.dark .dt-container .dt-paging .dt-paging-button:hover {
  background: #353535;
  border: 1px solid #353535;
}

html.dark .dt-container .dt-paging .dt-paging-button:active {
  background: #3a3a3a;
}

[dir="rtl"] table.dataTable thead th, [dir="rtl"] table.dataTable thead td, [dir="rtl"] table.dataTable tfoot th, [dir="rtl"] table.dataTable tfoot td {
  text-align: right;
}

[dir="rtl"] table.dataTable th.dt-type-numeric, [dir="rtl"] table.dataTable th.dt-type-date, [dir="rtl"] table.dataTable td.dt-type-numeric, [dir="rtl"] table.dataTable td.dt-type-date {
  text-align: left;
}

[dir="rtl"] div.dt-container div.dt-layout-cell.dt-start {
  text-align: right;
}

[dir="rtl"] div.dt-container div.dt-layout-cell.dt-end {
  text-align: left;
}

[dir="rtl"] div.dt-container div.dt-search input {
  margin: 0 3px 0 0;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control, table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  box-sizing: border-box;
  content: "";
  border: 5px solid #0000;
  border-left: 10px solid #00000080;
  border-right-width: 0;
  margin-right: .5em;
  display: inline-block;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control.arrow-right:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control.arrow-right:before {
  border: 5px solid #0000;
  border-left-width: 0;
  border-right: 10px solid #00000080;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.dtr-expanded > td.dtr-control:before, table.dataTable.dtr-inline.collapsed > tbody > tr.dtr-expanded > th.dtr-control:before {
  border: 5px solid #0000;
  border-top: 10px solid #00000080;
  border-bottom-width: 0;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td.dtr-control, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th.dtr-control {
  padding-left: .333em;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control, table.dataTable.dtr-column > tbody > tr > th.dtr-control, table.dataTable.dtr-column > tbody > tr > td.control, table.dataTable.dtr-column > tbody > tr > th.control {
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control:before, table.dataTable.dtr-column > tbody > tr > th.dtr-control:before, table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  box-sizing: border-box;
  content: "";
  border: 5px solid #0000;
  border-left: 10px solid #00000080;
  border-right-width: 0;
  display: inline-block;
}

table.dataTable.dtr-column > tbody > tr > td.dtr-control.arrow-right:before, table.dataTable.dtr-column > tbody > tr > th.dtr-control.arrow-right:before, table.dataTable.dtr-column > tbody > tr > td.control.arrow-right:before, table.dataTable.dtr-column > tbody > tr > th.control.arrow-right:before {
  border: 5px solid #0000;
  border-left-width: 0;
  border-right: 10px solid #00000080;
}

table.dataTable.dtr-column > tbody > tr.dtr-expanded td.dtr-control:before, table.dataTable.dtr-column > tbody > tr.dtr-expanded th.dtr-control:before, table.dataTable.dtr-column > tbody > tr.dtr-expanded td.control:before, table.dataTable.dtr-column > tbody > tr.dtr-expanded th.control:before {
  border: 5px solid #0000;
  border-top: 10px solid #00000080;
  border-bottom-width: 0;
}

table.dataTable > tbody > tr.child {
  padding: .5em 1em;
}

table.dataTable > tbody > tr.child:hover {
  background: none !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: .5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

table.dataTable > tbody > tr.child span.dtr-title {
  min-width: 75px;
  font-weight: bold;
  display: inline-block;
}

div.dtr-modal {
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: 10em 1em;
  position: fixed;
  top: 0;
  left: 0;
}

div.dtr-modal div.dtr-modal-display {
  z-index: 102;
  background-color: #f5f5f7;
  border: 1px solid #000;
  border-radius: .5em;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 75%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: auto;
  box-shadow: 0 12px 30px #0009;
}

div.dtr-modal div.dtr-modal-content {
  padding: 2.5em;
  position: relative;
}

div.dtr-modal div.dtr-modal-content h2 {
  margin-top: 0;
}

div.dtr-modal div.dtr-modal-close {
  text-align: center;
  cursor: pointer;
  z-index: 12;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 6px;
  right: 6px;
}

div.dtr-modal div.dtr-modal-background {
  z-index: 101;
  background: #0009;
  position: fixed;
  inset: 0;
}

@media screen and (width <= 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}

html.dark table.dataTable > tbody > tr > td.dtr-control:before, html[data-bs-theme="dark"] table.dataTable > tbody > tr > td.dtr-control:before {
  border-left-color: #ffffff80 !important;
}

html.dark table.dataTable > tbody > tr > td.dtr-control.arrow-right:before, html[data-bs-theme="dark"] table.dataTable > tbody > tr > td.dtr-control.arrow-right:before {
  border-right-color: #ffffff80 !important;
}

html.dark table.dataTable > tbody > tr.dtr-expanded > td.dtr-control:before, html.dark table.dataTable > tbody > tr.dtr-expanded > th.dtr-control:before, html[data-bs-theme="dark"] table.dataTable > tbody > tr.dtr-expanded > td.dtr-control:before, html[data-bs-theme="dark"] table.dataTable > tbody > tr.dtr-expanded > th.dtr-control:before {
  border-top-color: #ffffff80 !important;
  border-left-color: #0000 !important;
  border-right-color: #0000 !important;
}

html.dark table.dataTable > tbody > tr.child ul.dtr-details > li, html[data-bs-theme="dark"] table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom-color: #404346;
}

html.dark div.dtr-modal div.dtr-modal-display, html[data-bs-theme="dark"] div.dtr-modal div.dtr-modal-display {
  background-color: #212529;
  border: 1px solid #ffffff26;
}

/*# sourceMappingURL=datatables-css-dependencies.css.map */
